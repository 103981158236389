<div class="container">
    <mat-card class="content">
        <mat-card-header>
            <mat-card-title>Impressum</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <p class="bold">Geschäftsstelle, Mitgliederverwaltung & Inhaltsverantwortliche</p>
            <p>
                Turn- und Sportverein von 1860 e.V. Travemünde
                Sportplatz Rugwisch
                <br><br>
                Ivendorfer Landstraße 2A
                <br>
                23570 Lübeck-Travemünde
                <br><br>
                Tel.: 04502 - 9 99 81 38
                <br>
                Fax: 04502 - 9 99 81 37
                <br>
                info@tsvtravemuende.de
                <br><br>
                © 2023 TSV von 1860 e.V. Travemünde
            </p>
            <mat-divider></mat-divider>
            <p class="bold">Umsetzung & Hosting der Website</p>
            <p>
                M.IT<br>
                An der Bäk 44<br>
                23570 Lübeck<br>
                support@moes-it.com
            </p>
        </mat-card-content>
    </mat-card>
</div>